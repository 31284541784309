import React from 'react'
import SEO from '../components/seo/seo'
import Contact from '../components/contact/contact'


const ContactPage = () => (
  <>
    <SEO
      title="Contact"
      description="Contact Pleasant Street Title & Closing today. 603-540-6507"
      keywords={['pleasant street', 'pleasant street title & closing', 'title', 'title insurance', 'title search', 'closing', 'closings', 'buyer', 'buyers', 'seller', 'sellers', 'lender', 'lenders', 'real estate', 'realtor', 'broker', 'mortgage', 'real estate lawyer', 'real estate attorney', 'law', 'lawyer', 'law office', 'attorney', 'James Feleen', 'Jim Feleen', 'Claremont', 'NH', 'New Hampshire', 'Claremont NH', 'sullivan county', 'grafton county', 'cheshire county', 'merrimack county', 'Acworth', 'Charlestown', 'Cornish', 'Croydon', 'Goshen', 'Grantham', 'Langdon', 'Lempster', 'Newport', 'Plainfield', 'Springfield', 'Sunapee', 'Unity', 'Washington']} />
    <Contact />
  </>
)

export default ContactPage
