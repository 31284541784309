import EmbeddedMap from '../map/embeddedMap'
import React from 'react'
import { IconContext } from "react-icons";
import { FaEnvelope, FaPhone, FaMobileAlt } from 'react-icons/fa'

import './contact.css'

const Contact = props => (
  <IconContext.Provider value={{ color: "var(--primary-color)", style: { verticalAlign: 'middle' }}}>
      <div className="body-wrapper contact-body">
        <h1>Contact</h1>
        <p>
          Please contact Attorney Feleen by phone or email to arrange a meeting or consultation.
        </p>
        <div className="contact">
          <div className ="contact-info">
          <div className="address">
              <h4>Address for mail and overnight:</h4>
              <p>
                Law Office of James G. Feleen, PLLC<br></br>
                203 Mulberry St.<br></br>
                Claremont, NH 03743 <br></br>
              </p>
            </div>
            <div className="additional-questions">
              <p>
                <FaPhone />: (603) 504-6507 <br></br>
                <FaMobileAlt />: (603) 477-4671 <br></br>
                <FaEnvelope />: jim@feleenlaw.com
              </p>
            </div>
           
          </div>
          {/* <EmbeddedMap /> */}
        </div>
      </div>
      </IconContext.Provider>
  );


export default Contact;
